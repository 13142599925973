import styles from "./Header.module.scss";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import bottomArrowSVG from "../assets/bottomArrow.svg";
import userSVG from "../assets/user.svg";
import cartSVG from "../assets/cart.svg";
import LogoSVG from "../assets/Logo.svg";
import {useEffect, useRef, useState} from "react";
import classNames from "classnames";
import {useTranslation} from "react-i18next";

const Header = () => {

    const path = useLocation();
    const params = useParams()?.year;

    const navigation = useNavigate();

    const {t, i18n} = useTranslation();

    const [isOpenBurgerMenu, setIsOpenBurgerMenu] = useState(false);
    const [isOpenProfileMenu, setIsOpenProfileMenu] = useState(false);
    const [isOpenSelectLanguage, setIsOpenSelectLanguage] = useState(false);

    const overlayRef = useRef(null);
    const profileRef = useRef(null);
    const languageRef = useRef(null);

    useEffect(() => {
        const clickOutside = (event) => {
            if (languageRef.current && !languageRef.current.contains(event.target) && isOpenSelectLanguage) {
                setIsOpenSelectLanguage(false);
            }
        }
        document.addEventListener("mousedown", clickOutside);
        return () => {
            document.removeEventListener("mousedown", clickOutside);
        };
    }, [languageRef, isOpenSelectLanguage]);

    useEffect(() => {
        const clickOutside = (event) => {
            if (overlayRef.current && isOpenProfileMenu) {
                if (isOpenProfileMenu && !profileRef.current.contains(event.target)) {
                    setIsOpenProfileMenu(false)
                }
            }
        }
        document.addEventListener("mousedown", clickOutside);
        return () => {
            document.removeEventListener("mousedown", clickOutside);
        };
    }, [overlayRef, isOpenProfileMenu]);

    const parsePath = (lang) => {
        let pathArr = path.pathname.split("/");
        const index = pathArr.indexOf(i18n.language);
        if (index !== -1) {
            pathArr.splice(index, 1);
        }
        if (!!params && lang && i18n.language !== lang) {
            pathArr[1] = +params;
        }
        const newPath = pathArr.filter((item => item));
        return newPath.join("/");
    };

    const changeLanguageHandler = (lang) => {
        localStorage.setItem("localization", lang);
        navigation(`/${lang}/${parsePath(lang)}`);
        i18n.changeLanguage(lang);
    }

    return (
        <>
            <header className={styles.header}>
                <div
                    className={classNames(styles.burger, {
                        [styles.burgerAnimation]: isOpenBurgerMenu,
                    })}
                    onClick={() => {
                        setIsOpenBurgerMenu(prevState => !prevState)
                    }}
                    title={isOpenBurgerMenu ? "Close menu" : "Open menu"}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <img
                    src={LogoSVG}
                    alt="Logo"
                    width={100}
                    height={16}
                    className={styles.logo}
                    onClick={() => navigation(`/${i18n.language}`)}
                />

                <div className={styles.tools}>
                    <div className={styles.userInfo} ref={profileRef}
                         onClick={() => setIsOpenProfileMenu(prevState => !prevState)}
                    >
                        <div
                            className={styles.userInfo__icon}
                            title={isOpenProfileMenu ? "Close profile window" : "Open profile window"}
                        >
                            <img src={userSVG} alt="User icon" width="21" height="21"/>
                        </div>
                        <div
                            className={classNames(styles.userInfoWindow, {
                                [styles.visible]: isOpenProfileMenu,
                            })}
                        >
                            <ul>
                                <li>
                                    <a href={`https://sprinkle-th.com/${i18n.language}/product#59b0056a6aadff9ca`}>{t`Subscribe`}</a>
                                </li>
                                <li>
                                    <a
                                        className={styles.txt}
                                        href={`https://sprinkle-th.com/${i18n.language}/myprofile`}
                                    >
                                        {t`MemberPlatform`}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div
                        className={styles.cart}
                    >
                        <Link to={`https://sprinkle-th.com/${i18n.language}/checkout`}>
                            <img
                                src={cartSVG}
                                alt="Cart icon"
                                width="21"
                                height="21"
                            />
                        </Link>
                    </div>
                    <div
                        className={styles.languageDropdown}
                        onClick={() => setIsOpenSelectLanguage(prevState => !prevState)}
                        ref={languageRef}
                    >
                        <button
                            className={styles.languageSelector}
                            title={"Change language"}
                        >
                            <span>{i18n.language.toUpperCase()}</span>
                            <img src={bottomArrowSVG} alt="arrow"
                                 className={isOpenSelectLanguage ? styles.rotateArrow : styles.arrow}/>
                        </button>
                        <ul
                            id="language"
                            className={classNames(styles.language_list, {
                                [styles.visible]: isOpenSelectLanguage,
                            })}
                        >
                            <li>
                                <button value="en" onClick={() => {
                                    changeLanguageHandler("en");
                                }}>
                                    EN
                                </button>
                            </li>
                            <li>
                                <button value="th" onClick={() => {
                                    changeLanguageHandler("th");
                                }}>
                                    TH
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>

                <nav
                    className={classNames(styles.navigation, {
                        [styles.visible]: isOpenBurgerMenu,
                    })}
                    role="navigation"
                >
                    <ul className={styles.navigation__main}>
                        <li><Link className={styles.navLink} to={`/${i18n.language}`}>{t`Home`}</Link></li>
                        <li>
                            <a
                                className={styles.navLink}
                                href={`https://sprinkle-th.com/${i18n.language}/product`}
                            >
                                {t`Products`}
                            </a>
                        </li>
                        <li>
                            <a
                                className={styles.navLink}
                                href={`https://sprinkle-th.com/${i18n.language}/corporate`}
                            >
                                {t`CorporateInfo`}
                            </a>
                        </li>
                        <li>
                            <a
                                className={styles.navLink}
                                href={`https://sprinkle-th.com/${i18n.language}/faq`}
                            >
                                {t`FAQ`}
                            </a>
                        </li>
                        <li>
                            <a
                                className={styles.navLink}
                                href={`https://sprinkle-th.com/${i18n.language}/contact`}
                            >
                                {t`ContactUs`}
                            </a>
                        </li>
                    </ul>
                    <ul className={styles.navigation__actions}>
                        <li>
                            <a
                                target="_blank"
                                href={`https://sprinkle-th.com/${i18n.language}/product#59b0056a6aadff9ca`}
                            >
                                {t`Subscribe`}
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href={`https://delivery.sprinkle-th.com/line/${i18n.language}/supp_sendwater.html?channel=เว็บไซต์`}
                            >
                                {t`ConfirmWater`}
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href={`https://mpayment.sprinkle-th.com/pay-invoice/mwater/${i18n.language}`}
                            >
                                {t`PayInvoice`}
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href={`https://mpayment.sprinkle-th.com/pay-invoice/bett/${i18n.language}`}
                            >
                                {t`PayInvoiceSmall`}
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href={`https://refer.sprinkle-th.com/${i18n.language}`}
                            >
                                {t`Refer`}
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank" href={"https://old.sprinkle-th.com/career.php"}
                            >
                                {t`WorkWithUs`}
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href={"https://drive.google.com/drive/folders/1XuwyErvfcSSvjvX4KIGhHOfJfGXfahNC?usp=sharing"}
                            >
                                {t`FreshMagazine`}
                            </a>
                        </li>
                    </ul>
                </nav>
            </header>
            <div ref={overlayRef} className={classNames(styles.overlay, {
                [styles.visible]: isOpenProfileMenu
            })}/>
        </>)
}

export default Header;