import styles from './Home.module.scss';
import Illustration from "../assets/illustration.svg";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import {useEffect, useState} from "react";
import {Card} from "../components/ui-kit";
import {Link, useNavigate, useParams} from "react-router-dom";
import client from "../api/client";
import {getCurrentDate} from "../helpers/getCurrentDate";

const Home = () => {

    const {i18n} = useTranslation();

    const filterYear = useParams().year;

    const history = useNavigate();

    const [selectedYear, setSelectedYear] = useState(filterYear ?? new Date().getFullYear());
    const [posts, setPosts] = useState([]);
    const [years, setYears] = useState([]);
    const [settings, setSettings] = useState([]);

    useEffect(() => {
        getYears();
        getSettings();
    }, []);

    useEffect(() => {
        getPosts();
    }, [years, selectedYear, filterYear]);

    useEffect(() => {
        setNearestYear();
    }, [filterYear, years]);

    const setNearestYear = () => {
        const WITHOUT_YEAR = !filterYear && !years.includes(new Date().getFullYear());
        if(WITHOUT_YEAR && years.length) {
            setSelectedYear(years[0]);
            history(`/${i18n.language}/${years[0]}`);
        }
    }

    const translationIndex = i18n.language === "en" ? 0 : 1;
    const fallbackTranslationIndex = 1 - translationIndex;

    const getSettings = async () => {
        try {
            const {data} = await client.get({
                url: `settings`
            });
            setSettings(data?.settings?.translations)
        } catch (e) {
            throw new Error(e?.response?.data?.message);
        }
    }

    const getYears = async () => {
        try {
            const {data} = await client.get({
                url: `posts/years-with-posts`
            });
            setYears(data.available_years.sort((firstYear, secondYear) => firstYear > secondYear ? -1 : 1));
        } catch (e) {
            throw new Error(e?.response?.data?.message);
        }
    }

    const getPosts = async () => {
        try {
            const {data} = await client.get({
                url: `posts-by-year/${selectedYear}`
            });
            setPosts(data.posts.data)
        } catch (e) {
            setPosts([]);
            throw new Error(e?.response?.data?.message);
        }
    }

    return (<main className={styles.home}>
        <section className={styles.about}>
            <div className={styles.info}>
                {!!settings[translationIndex]?.site_title
                    ?
                    <div className={styles.support}>
                        {settings[translationIndex]?.site_title}
                    </div>
                    :
                    <div className={styles.support}>
                        {settings[fallbackTranslationIndex]?.site_title}
                    </div>
                }

                <h1>
                    {settings[translationIndex]?.title || settings[fallbackTranslationIndex]?.title}
                </h1>
                <p className={styles.description}
                >
                    {settings[translationIndex]?.subtitle || settings[fallbackTranslationIndex]?.subtitle}
                </p>
            </div>
            <div className={styles.picture}>
                <img src={Illustration} alt="Main illustration" width={346} height={200} loading={"lazy"}/>
            </div>
        </section>
        <section className={styles.filters}>
            {years.map(year => {
                const thaiYear = year + 543;
                return <Link
                    className={classNames(styles.year, {
                        [styles.selected]: year == selectedYear
                    })}
                    to={`/${i18n.language}/${year}`}
                    key={year}
                    onClick={() => {
                        setSelectedYear(year);
                        setPosts([]);
                    }}
                >
                    {i18n.language === 'en' ? year : thaiYear}
                </Link>
            })}
        </section>
        <section className={styles.content}>
            {!!years.length && posts.map((item) => {
                return <Link to={`/${i18n.language}/post/${item.id}`} key={item.id}>
                    <Card
                        date={getCurrentDate(item.created_at, i18n.language)}
                        title={item.translations[translationIndex].title}
                        description={item.translations[translationIndex].subtitle}
                        img={item.media[0]?.original_url}
                    />
                </Link>
            })}
        </section>
    </main>)
}

export default Home;