import styles from "./Footer.module.scss";
import Illustration from "../assets/illustration_footer.svg"
import LogoSVG from "../assets/Logo.svg"
import mailSVG from "../assets/mail.svg"
import phoneSVG from "../assets/phone.svg"
import FacebookSVG from "../assets/Facebook.svg"
import instagramSVG from "../assets/instagram.svg"
import youtubeSVG from "../assets/youtube.svg"
import LineSVG from "../assets/LINE.svg"
import visaSVG from "../assets/visa.svg"
import qrSVG from "../assets/qr.svg"
import mastercardSVG from "../assets/mastercard.svg"
import AlipaySVG from "../assets/Alipay.svg"
import wechatSVG from "../assets/wechat.svg"
import line_paySVG from "../assets/line_pay.svg"
import Reward1SVG from "../assets/r1.svg"
import Reward2SVG from "../assets/r2.svg"
import Reward3SVG from "../assets/r3.svg"
import Reward4SVG from "../assets/r4.svg"
import Reward5SVG from "../assets/r5.svg"
import Reward6SVG from "../assets/r6.svg"
import Reward7SVG from "../assets/r7.svg"
import Reward8SVG from "../assets/r8.svg"
import Reward9SVG from "../assets/r9.svg"
import Reward10SVG from "../assets/r10.svg"
import classNames from "classnames";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Footer = () => {

    const path = useLocation();
    const params = useParams();
    const navigation = useNavigate();

    const { t, i18n } = useTranslation();

    const parsePath = (lang) => {
        let pathArr = path.pathname.split("/");
        const index = pathArr.indexOf(i18n.language);
        if (index !== -1) {
            pathArr.splice(index, 1);
        }
        if (!!params?.year && lang && i18n.language !== lang) {
            pathArr[1] = +params.year;
        }
        const newPath = pathArr.filter((item => item));
        return newPath.join("/");
    };

    const changeLanguageHandler = (lang) => {
        localStorage.setItem("localization", lang)
        navigation(`/${lang}/${parsePath(lang)}`);
        i18n.changeLanguage(lang);
    }

    const thaiCurrentYear = new Intl.DateTimeFormat('th', {year: "numeric"}).format(new Date()).split(' ')[1];
    const internationalCurrentYear = new Date().getFullYear();
    const copyrightYear = i18n.language === "en" ? internationalCurrentYear : thaiCurrentYear;

    return (
        <footer className={styles.footer}>
            <div className={styles.footer_content}>
                <div className={styles.footer_content__navigation}>
                    <img
                        src={LogoSVG}
                        alt="Logo"
                        width={100}
                        height={16}
                        onClick={() => navigation(`/${i18n.language}`)}
                    />
                    <hr/>
                    <nav>
                        <a href="https://sprinkle-th.com/en/product" title="Products">{t(`Products`)}</a>
                        <a href="https://sprinkle-th.com/en/corporrate" title="Corporate info">{t(`CorporateInfo`)}</a>
                        <a href="https://sprinkle-th.com/en/contact" title="Contact us">{t(`ContactUs`)}</a>
                        <a href="https://sprinkle-th.com/en/faq" title="FAQ">{t(`FAQ`)}</a>
                    </nav>
                </div>

                <div className={styles.footer_content__info}>

                    <div className={styles.contact_us}>
                        <h3>{t(`ContactUs`)}:</h3>

                        <ul>
                            <li>
                                <a href="tel:027127272" title="Tel">
                                    <img src={phoneSVG} alt="tel" height={16} width={17}/>
                                    <div>
                                        <b>Tel: 02-712-7272</b>
                                        <span>Call Center</span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="mailto:info@sprinkle-th.com" title="email">
                                    <img src={mailSVG} alt="email" width={17} height={16}/>
                                    <div>
                                        <b>info@sprinkle-th.com</b>
                                        <span>Email</span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="https://line.me/R/ti/p/@sprinklewater" title="LINE" target="_blank" rel="noreferrer">
                                    <img src={LineSVG} alt="LINE" width={17} height={16}/>
                                    <div>
                                        <b>@sprinklewater</b>
                                        <span>LINE</span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.facebook.com/SprinkleDrinkingWater" title="Facebook"
                                   target="_blank" rel="noreferrer">
                                    <img src={FacebookSVG} alt="fb: SprinkleDrinkingWater" width={17} height={16}/>
                                    <div>
                                        <b>SprinkleDrinkingWater</b>
                                        <span>Facebook</span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/sprinkle_drink" title="Instagram" target="_blank" rel="noreferrer">
                                    <img src={instagramSVG} alt="Instagram" width={17} height={16}/>
                                    <div>
                                        <b>SprinkleDrinking</b>
                                        <span>Instagram</span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/channel/UC0MJ2Z16ma2Ts8zH4gPLOYw" title="YouTube"
                                   target="_blank" rel="noreferrer">
                                    <img src={youtubeSVG} alt="YouTube" width={23} height={16}/>
                                    <div>
                                        <b>Sprinkle-channel</b>
                                        <span>YouTube</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div>

                        <div className={styles.support}>
                            <h3>{t(`WeSupport`)}:</h3>

                            <div className={styles.support__list}>
                                <div className={styles.support__element}>
                                    <img src={qrSVG} alt="QR code" width={80} height={18}/>
                                </div>
                                <div className={styles.support__element}>
                                    <img src={visaSVG} alt="Visa" width={33} height={16}/>
                                </div>
                                <div className={styles.support__element}>
                                    <img src={mastercardSVG} alt="Mastercard" width={21} height={16}/>
                                </div>
                                <div className={styles.support__element}>
                                    <img src={AlipaySVG} alt="Alipay" width={45} height={16}/>
                                </div>
                                <div className={styles.support__element}>
                                    <img src={wechatSVG} alt="WeChat" width={57} height={16}/>
                                </div>
                                <div className={styles.support__element}>
                                    <img src={line_paySVG} alt="Line pay" width={49} height={16}/>
                                </div>
                            </div>

                        </div>

                        <div className={styles.rewards}>
                            <h3>{t`OurRewards`}:</h3>

                            <div className={styles.rewards__list}>
                                <a href="https://drive.google.com/file/d/13SUSUkh_aj7BF7yCDym9MZvfbKQjP5Am/preview"
                                   target="_blank" rel="noreferrer">
                                    <img src={Reward1SVG} alt="rewards" width={31} height={24}/>
                                </a>
                                <a href="https://drive.google.com/file/d/154lakk9DF4c_hB0-6oisZzR0dy0IIpBe/preview"
                                   target="_blank" rel="noreferrer">
                                    <img src={Reward2SVG} alt="rewards" width={23} height={22}/>
                                </a>
                                <a href="https://drive.google.com/file/d/154lakk9DF4c_hB0-6oisZzR0dy0IIpBe/preview"
                                   target="_blank" rel="noreferrer">
                                    <img src={Reward3SVG} alt="rewards" width={23} height={18}/>
                                </a>
                                <a href="https://drive.google.com/file/d/1Du_NpepIZCochM33FZz2c-QntHCm3KuC/preview"
                                   target="_blank" rel="noreferrer">
                                    <img src={Reward4SVG} alt="rewards" width={21} height={21}/>
                                </a>
                                <a href="https://drive.google.com/file/d/1Du_NpepIZCochM33FZz2c-QntHCm3KuC/preview"
                                   target="_blank" rel="noreferrer">
                                    <img src={Reward5SVG} alt="rewards" width={24} height={25}/>
                                </a>
                                <a href="https://drive.google.com/file/d/13GoweaNEfsBuEpFhI2iMxAdPCSiuG9lI/preview"
                                   target="_blank" rel="noreferrer">
                                    <img src={Reward6SVG} alt="rewards" width={25} height={22}/>
                                </a>
                                <a href="https://drive.google.com/file/d/1QD4jpeSLWzD2VygwB2UIlzUe6x7WFD4h/preview"
                                   target="_blank" rel="noreferrer">
                                    <img src={Reward7SVG} alt="rewards" width={21} height={24}/>
                                </a>
                                <a href="https://drive.google.com/file/d/1SH3BhJQkJns7g0OrXLPHmJ1Y2LZcPFI5/preview"
                                   target="_blank" rel="noreferrer">
                                    <img src={Reward8SVG} alt="rewards" width={31} height={17}/>
                                </a>
                                <a href="https://drive.google.com/file/d/1v9nbGqhW3iQ8ztgBX4YbBtw-aA_YuDZf/preview"
                                   target="_blank" rel="noreferrer">
                                    <img src={Reward9SVG} alt="rewards" width={22} height={22}/>
                                </a>
                                <a href="https://drive.google.com/file/d/11tI22y8TYnEbl3yGlBJWWQ7gFZXgwn2X/preview"
                                   target="_blank" rel="noreferrer">
                                    <img src={Reward10SVG} alt="rewards" width={21} height={22}/>
                                </a>
                            </div>

                        </div>
                    </div>

                </div>

                <div className={styles.footer_lang}>
                    <button
                        className={classNames({[styles.select]: i18n.language === 'en'})}
                        onClick={() => {
                            changeLanguageHandler("en");
                        }}
                    >
                        EN
                    </button>
                    <button
                        onClick={() => {
                            changeLanguageHandler("th");
                        }}
                        className={classNames({[styles.select]: i18n.language === 'th'})}
                    >
                        TH
                    </button>
                </div>

                <div className={styles.copyright}>© {copyrightYear} {t`Copyright`}</div>
                <div className={styles.illustration}>
                    <img src={Illustration} alt="bottles" width={260} height={250} loading={"lazy"}/>
                </div>
            </div>
        </footer>
    )
}

export default Footer;