import axios from 'axios';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({ baseURL: REACT_APP_API_URL });

const requests = {
    get: ({url, config}) => axiosInstance.get(url, config),

    post: ({url, body, config}) => axiosInstance.post(url, body, config),
};

export default requests;
