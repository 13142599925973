import translationEN from "../locales/en/translation.json";
import translationTH from "../locales/th/translation.json";
import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
        translation: translationEN
    },
    th: {
        translation: translationTH
    }
};

i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "th",

        keySeparator: false,

        interpolation: {
            escapeValue: false
        },

        react: {
            wait: true
        }
    });

export default i18n;